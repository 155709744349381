import "./allRoutes.styles.scss";

const Recipes = function () {
  return (
    <div className="comingSoonBox">
      <p className="tempHead">
        Recipes <br /> Coming Soon!
      </p>
    </div>
  );
};

export default Recipes;
