import { useContext, useEffect } from "react";
import { allProductContext } from "../context/products.context";

import ProductCard from "../components/shopForm/productCard.component";
import BundleCard from "../components/bundleCard/bundleCard.component";

const Shop = () => {
  const { allProducts } = useContext(allProductContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="shopContainer">
      <p className="mediumHeader">Bundles</p>
      <div className="bundlesContainer grid4col">
        {allProducts.slice(10, 14).map((product) => (
          <BundleCard key={product.id} product={product} />
        ))}
      </div>
      <div className="alaCarte">
        <p className="mediumHeader">{allProducts[1].category}</p>
        <p className="shopNotes"></p>
        <div className="allProductsContainer grid5col2row">
          {allProducts.slice(0, 9).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
        <p className="mediumHeader">{allProducts[6].category}</p>
        <p className="shopNotes"></p>
        <div className="allProductsContainer grid5col2row">
          {allProducts.slice(5, 9).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shop;
